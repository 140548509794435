@import 'overrides';
@import 'modal';

$fontSizeListTitle: 1.1rem;
$fontSizeListExcerpt: 1rem;

// social

.icon-social:hover {
    transition: 0.15s;
    transform: rotate(10deg) scale(1.1);
}

// others

.display-none {
    display: none;
}

.post-card {
    margin-bottom: 1rem;
    padding-right: 0.6rem;
}

.post-card-category {
    margin: 0px;
    font-size: 14px;
    font-weight: bold;
    color: $blue;
}

.post-card-header {
    line-height: normal;
}

.post-card-header h2 {
    font-size: $fontSizeListTitle;
}

.post-card-header-title {
    font-size: 18px;
    font-weight: bold;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.post-card-excerpt {
    font-size: 16px;
    line-height: normal;
}

.post-card-excerpt p {
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: $fontSizeListExcerpt;
}

.post-card-meta {
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin-top: 0.5rem;
    margin-bottom: 0px;
}

.static-avatar {
    display: block;
    overflow: hidden;
    margin: 0 0 0 -6px;
    width: 34px;
    height: 34px;
    border: 2px solid #fff;
    border-radius: 100%;
}

.author-name-tooltip {
    position: absolute;
    bottom: 105%;
    z-index: 999;
    display: block;
    padding: 2px 8px;
    color: #fff;
    font-size: 1.2rem;
    letter-spacing: .2px;
    white-space: nowrap;
    background: #15171a;
    border-radius: 3px;
    box-shadow: 0 12px 26px rgba(39,44,49,.08), 1px 3px 8px rgba(39,44,49,.03);
    opacity: 0;
    transition: all .35s cubic-bezier(.4,.01,.165,.99);
    transform: translateY(6px);
    pointer-events: none;
}

.author-profile-image {
    display: block;
    width: 100%;
    height: 100%;
    background: #e3e9ed;
    border-radius: 100%;
    object-fit: cover;
}

.post-card-byline-content {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    margin: 2px 0 0 6px;
    color: #92a3ab;
    font-size: 0.6rem;
    line-height: 1.4em;
    font-weight: 400;
    letter-spacing: .2px;
    text-transform: uppercase;
}

.post-card-byline-date {
    font-size: 100%;
}

.post-card-byline-content span {
    margin: 0;
}


// Dentro do modal de busca:
#search-input {
    width: 100% !important;
    padding: 12px 20px !important;
    margin: 8px 0 !important;
    display: inline-block !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    box-sizing: border-box !important;
}

#search-results {
    list-style-type: none;
    text-align: left;
    padding: 0px !important;
    padding-bottom: 10px !important;
}

.search-result-item {
    padding-bottom: 10px;
}

.search-result-item a:hover {
    color: $green !important;
}

.search-result-item a:focus {
    color: $green !important;
}

// Scale
.twenty-p {
    width: 20%;
}

.thirty-p {
    width: 30%;
}

.fifty-p {
    width: 50%;
}

.sixty-p {
    width: 60%;
}

.seventy-p {
    width: 70%;
}

.eighty-p {
    width: 80%;
}

.ninety-p {
    width: 90%;
}


.sponsored-image-container {
    padding-top: 2em;
}

.menu-container {
    text-align: left;
    margin-left: 2.1rem;
}

.lead {
    font-weight: bolder;
}

p.post-series-paragraph {
    margin: 0;
}

ul.series {
    margin: 0;
}